<template>
    <div id="about-page" class="page-wrapper" v-if="pageIsLoaded">
        <!-- Hero -->
        <section class="main-hero">
            <div class="col col-content">
                <h1 class="big-title" data-inview="fadeInUp" data-delay="100">
                    {{ content.hero.titre }}
                </h1>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    {{ content.hero.texte }}
                </p>
                <div data-inview="fadeInUp" data-delay="400">
                    <a
                        href="#mission"
                        v-scroll-to:-70
                        class="site-btn alternate"
                        :title="content.hero.bouton.customText"
                        >{{ content.hero.bouton.customText }}</a
                    >
                </div>
            </div>
            <div class="col col-img" data-inview="revealRight" data-delay="100">
                <img :src="content.hero.image.url" :alt="content.hero.image.title" />
            </div>
        </section>

        <!-- Infos swipe -->
        <section id="mission" class="infos-swipe">
            <div class="infos-swipe-inner">
                <div class="infos">
                    <div class="col col-img">
                        <img :src="content.split.image.url" :alt="content.split.titre" />
                    </div>
                    <div class="col col-content">
                        <h2 class="small-title" data-inview="fadeInUp" data-delay="100">
                            {{ content.split.titre }}
                        </h2>
                        <p class="regular-text" data-inview="fadeInUp" data-delay="200">
                            {{ content.split.texte }}
                        </p>
                        <div data-inview="fadeInUp" data-delay="400">
                            <a
                                href="#rendez-vous"
                                class="site-btn white"
                                :title="content.split.bouton.customText"
                                @click.prevent="togglePanel('rendez-vous')"
                                >{{ content.split.bouton.customText }}</a
                            >
                        </div>
                    </div>
                </div>
                <div class="swipe">
                    <div class="section-heading-n-btn">
                        <h2 class="regular-title" data-inview="fadeInUp" data-delay="100">
                            {{ $t('Nos valeurs') }}
                        </h2>
                    </div>
                    <div class="thumbs">
                        <div
                            class="thumb"
                            v-for="(item, i) in content.liste"
                            :key="i"
                            data-inview="fadeInUp"
                            :data-delay="i * 100"
                        >
                            <p class="big-text title">{{ item.titre }}</p>
                            <p class="small-text text">
                                {{ item.texte }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <svg
                class="particle"
                height="46"
                viewBox="0 0 315 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="fadeInUp"
                data-delay="400"
            >
                <path
                    d="M0.247544 0C3.46562 1.89689 6.55992 3.9175 9.61297 5.89686C12.666 7.87623 15.9253 9.64941 18.9784 11.5463L28.3851 16.8246C31.5619 18.5566 34.7387 20.1236 37.9155 21.773C50.4423 28.06 63.419 33.408 76.7387 37.7729C102.837 46.2878 130.108 50.6706 157.562 50.7625C185.027 50.7192 212.314 46.3638 238.426 37.8554C251.762 33.4982 264.753 28.1499 277.291 21.8555C280.426 20.2473 283.644 18.639 286.821 16.9483L296.228 11.6288C299.322 9.73188 302.499 7.91746 305.593 6.02057C308.688 4.12368 311.782 1.89689 315 0.0824755C312.978 3.21647 310.874 6.22676 308.564 9.23705C306.253 12.2473 303.819 15.0514 301.385 17.8968C298.951 20.7421 296.228 23.34 293.587 26.1441C292.308 27.5462 290.864 28.7008 289.462 30.0204L285.336 33.8142C273.724 43.7532 260.935 52.2289 247.255 59.0511C245.564 59.9583 243.79 60.7006 242.057 61.5253L236.776 63.917C235.043 64.7418 233.228 65.3191 231.413 66.0613L226.008 68.1232C222.377 69.4428 218.705 70.5562 214.992 71.752C211.279 72.9479 207.525 73.7314 203.77 74.7211C188.646 78.2304 173.171 80.0014 157.644 79.9994C126.547 80.0766 95.8565 72.9337 67.9921 59.1336C54.3074 52.3205 41.5171 43.844 29.9116 33.8967L25.7859 30.1029C24.4244 28.8245 22.9391 27.6286 21.6601 26.2678C19.0196 23.505 16.3379 20.9071 13.8625 18.0205C11.387 15.1339 8.99411 12.2886 6.68369 9.36075C4.37328 6.43294 2.06287 3.29894 0 0.206181L0.247544 0Z"
                    fill="#BEE0D9"
                />
            </svg>
        </section>

        <!-- Team grid -->
        <section class="team-grid">
            <div class="section-heading-n-btn">
                <h2 class="regular-title" data-inview="fadeInUp" data-delay="100">
                    <span v-html="$t('Rencontrez les membres de notre équipe <br> qui contribuent à faire la différence')"></span>
                </h2>
            </div>
            <div class="grid">
                <router-link
                    :to="item.jsonUrl"
                    class="team-thumb"
                    :title="item.title"
                    data-inview="fadeInUp"
                    data-delay="100"
                    v-for="(item, i) in equipeDentistes"
                    :key="i"
                >
                    <div class="img-wrap">
                        <img :src="item.image.url" :alt="item.title" />
                        <svg
                            class="plus"
                            height="45"
                            viewBox="0 0 60 60"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="30" cy="30" r="30" fill="white" />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M30 40C30.9266 40 31.6931 39.2335 31.6931 38.3069V31.6931H38.3069C39.2335 31.6931 40 30.9266 40 30C40 29.0734 39.2335 28.3069 38.3069 28.3069H31.6931V21.6931C31.6931 20.7665 30.9266 20 30 20C29.0734 20 28.3069 20.7665 28.3069 21.6931V28.3069H21.6931C20.7665 28.3069 20 29.0734 20 30C20 30.9266 20.7665 31.6931 21.6931 31.6931H28.3069V38.3069C28.3069 39.2335 29.0734 40 30 40V40Z"
                                fill="#565B6E"
                            />
                        </svg>
                    </div>
                    <div class="content">
                        <p class="regular-text title">{{ item.title }}</p>
                        <p class="small-text text">{{ item.poste }}</p>
                    </div>
                </router-link>
            </div>
            <div class="grid four" data-inview="fadeInUp" data-delay="800">
                <article class="team-thumb no-hover" v-for="(item, i) in equipeStaff" :key="i">
                    <div class="img-wrap no-hover">
                        <img :src="item.image.url" :alt="item.title" />
                    </div>
                    <div class="content">
                        <p class="regular-text title">{{ item.title }}</p>
                        <p class="small-text text">{{ item.poste }}</p>
                    </div>
                </article>
            </div>
        </section>
    </div>
    <PageLoader v-else />
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'

import { mapState, mapGetters, mapActions } from 'vuex'
import PageLoader from '@/components/PageLoader.vue'

export default {
    name: 'About',

    components: {
        PageLoader,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page load buffer
                    setTimeout(() => {
                        initInViewAnimations()
                    }, 155)
                }
            },
            immediate: true,
        },
    },

    computed: {
        equipeDentistes() {
            if (!this.globalsIsLoaded) {
                return []
            }
            return this.globals.equipeMembres.data.filter(i => i.poste === 'Dentiste')
        },
        equipeStaff() {
            if (!this.globalsIsLoaded) {
                return []
            }
            return this.globals.equipeMembres.data.filter(i => i.poste !== 'Dentiste')
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        ...mapActions(['togglePanel']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-wrapper {
    min-height: 90vh;
}
</style>
